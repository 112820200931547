export const filterA = [
  ["Her", "Him", "Mum", "Dad", "Husband", "Wife", "Son", "Daughter", "Brother", "Sister"],
  ["Grandad", "Grandma", "Aunt", "Uncle", "Cousin", "Niece", "Nephew"],
  [
    "Father-in-Law",
    "Mother-in-Law",
    "Brother-in-Law",
    "Sister-in-Law",
    "Son-in-Law",
    "Daughter-in-Law",
  ],
  ["Boyfriend", "Girlfriend", "Fiancee"],
  ["Friend"],
];

export const male = [
  "Dad",
  "Husband",
  "Son",
  "Brother",
  "Grandad",
  "Uncle",
  "Father-in-Law",
  "Brother-in-Law",
  "Son-in-Law",
  "Boyfriend",
  "Nephew",
  "Cousin",
  "Friend",
  "Colleague",
  "Relative",
  "Neighbour",
  "Other"
]

export const female = [
  "Her",
  "Wife",
  "Daughter",
  "Sister",
  "Grandma",
  "Aunt",
  "Mother-in-Law",
  "Sister-in-Law",
  "Daughter-in-Law",
  "Girlfriend",
  "Fiancee",
  "Niece",
  "Cousin",
  "Friend",
  "Colleague",
  "Relative",
  "Neighbour",
  "Other",
]

export const filterB = [
  "Birthday",
  "Thank You",
  "Anniversary",
  "Apology",
  "Christmas",
  "Congratulations",
  "Friendship",
  "Graduation",
  "Love You",
  "New Baby",
  "New House",
  "Promotion",
  "Retirement",
  "Sympathy",
  "Thinking of You",
  "Valentine's Day",
  "Mothers Day",
  "Fathers Day",
  "Easter",
  "Halloween",
  "Engagement",
  "Wedding"
];

export const filterC = [
  "1st Birthday",
  "13th Birthday",
  "16th Birthday",
  "18th Birthday",
  "21st Birthday",
  "30th Birthday",
  "40th Birthday",
  "50th Birthday",
  "60th Birthday",
  "70th Birthday",
  "80th Birthday",
  "90th Birthday",
  "100th Birthday",
];

export const filterD = [
  "Photo",
  "Animals",
  "Funny / Rude",
  "Celebrities",
  "Classic",
  "LGBTQ",
  "Sentimental",
  "Sports",
  "TV / Movies",
  "Hobbies",
  "Kids",
  "Gaming",
];
